<template>
    <div class="wh100 monitoring_main_box">
        <HnContent :hasData='hasData'>
           <template slot="left">
                <HnMenu :menuDevType='menuDevType' :actuatorFun="1"  @change='devChange'></HnMenu>
           </template>
           <template slot="right">
                <div 
                    v-loading="changLoading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(40, 53, 63, 0.8)"
                    class="wh100 boxBg content_box">
                    <div class="nowData_title_box">
                        <div>
                            <span class="f16">实时数据</span>
                            <span>更新时间：{{ updateTime }}</span>
                        </div>
                        <div>
                            <!-- <div class="but_box but_hover">实时查询</div> isOnlie   -->
                            <el-tooltip :disabled='isOnlie' effect="dark" content="设备不在线，无法查询！" placement="top">
                                <template v-if="isOnlie">
                                    <el-button @click="updataNowData" :loading="loadData.updateLoad" size="mini">实时查询</el-button> 
                                </template>
                                <template v-else>
                                    <el-button size="mini">实时查询</el-button>
                                </template>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="nowData_content_box monitoring-nowdata-bg-image" ref="warp">
                        <ul class="nowDataList" :style="leftStyle" :key="timer">
                            <li class="nowDataItem" v-for="(item,i) of nowDataList" :key="i">
                                <div>
                                    <div :style="paramOutIconHanlde(i)"></div>
                                    <span :class="['iconfont', item.paramIcon]" :style="paramInIconHanlde(i)"></span>
                                </div>
                                <div>
                                    <p>
                                        <span :class="item.paramName !== '风向'?'paramNub':'paramWind'">{{ paramValueHandle(item) }}</span>
                                        <span>{{ item.paramName === '风向'?'': item.paramUnit }}</span>
                                    </p>
                                    <p>{{ item.paramName }}</p> 
                                </div>
                            </li>
        

                        </ul>
                    </div>
                    <div class="history_title_box">
                        <div class="title_box">
                            <span class="f16">历史数据</span>
                            <span>采集总数：<span class="baseColor fontfanmily">{{ historyTotalData }}</span>次</span>
                        </div>
                        <div class="check_box">
                            <el-date-picker
                            v-model="searchParamDateVal"
                            @change="searchParamDateChange"
                            type="daterange"
                            align="right"
                            size="small"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            >
                            </el-date-picker>
                            <ul class="boxsubBg dateType_box">
                                <li 
                                :class="[devHositoryFromInfo.timeType == item.code? 'active':'', 'weather-cp']"
                                v-for="item of paramTimeList"
                                :key="item.code"
                                @click="paramTimeChange(item.code)"
                                >{{ item.name }}</li>
                            </ul>
                        </div>
                        <div class="tools_box">
                            <!-- <div class="but_hover">预警订阅</div> -->
                            <!-- <el-popover
                            placement="left"
                            v-model="warnVisbise"
                            width="400"
                            trigger="click">
                            <template v-if="paramCount">
                                <el-form ref="warnform" :model="warnform" label-width="90px">
                                    <el-form-item label="是否接收">
                                        <el-radio-group v-model="warnform.isReceive" size="mini" >
                                            <el-radio :label="1" border>是</el-radio>
                                            <el-radio :label="2" border>否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="传感器选择">
                                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                                    <el-checkbox-group v-model="warnform.collectorIds" size="small" @change="handleCheckedCitiesChange">
                                        <template v-for="(item,i) of devHistoryParamsList">
                                                <el-checkbox :label="item.collectorSensorId" :key="item.collectorSensorId" >{{ item.paramName }}</el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="tr">
                                        <el-button @click="onCalce" size='small'>取消</el-button>
                                        <el-button type="primary" @click="onSubmit" class="but_box" size='small' :loading="loadData.submitLoad">设置</el-button>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </template>
                            <template v-else>
                            <el-alert
                                    title="无法订阅，请先添加传感器！"
                                    type="success"
                                    :closable= 'false'
                                    show-icon>
                                </el-alert>
                            </template>
                            <el-button :loading="loadData.alarmLoad" size="medium" slot="reference">预警订阅</el-button>
                            </el-popover> -->
                            
                            <!-- <div class="cp">导出</div>  -->
                            <el-button type="warning" plain size="mini" :loading="loadData.downLoad" @click="reqIotDeviceHistoryDataExport" class="downBut cp">导出</el-button>
                        </div>
                    </div>
                    <div class="history_content_box boxScroll">

                        <template v-if="devHistoryParamsList.length">
                            <template v-for="(item,i) of devHistoryParamsList">
                                <div class="pageBg" :key="item.collectorSensorId">
                                <div class="left_history_box">
                                    <p class="baseBorderLeftColor">
                                        {{ item.paramName }}( <span>{{item.paramUnit }}</span> )
                                    </p>
                                    <div>
                                        <div>
                                            <div class="paramIcon">
                                                <div>
                                                    <div :style="paramOutIconHanlde(i)"></div>
                                                    <span :class="item.paramIcon" :style="paramInIconHanlde(i)"></span>
                                                </div>
                                            </div>
                                            <div>
                                                <template v-if="item.paramName !== '风向'">
                                                    <p>最新值：<span class="baseColor nubFont">{{ item.paramVal !== null ? item.paramVal+item.paramUnit :'无数据' }}</span></p>
                                                    <p>最大值：<span class="nubFont">{{ item.maxVal !== null ? item.maxVal+item.paramUnit :'无数据' }}</span></p>
                                                    <p>最小值：<span class="nubFont">{{ item.minVal !== null ? item.minVal+item.paramUnit :'无数据' }}</span></p>
                                                </template> 
                                                <template v-else>
                                                    <div :style="paramInIconHanlde(i)">{{ paramValueHandle(item) }}</div>
                                                </template>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="flex_content">
                                                <div>
                                                    <span>告警阈值</span>
                                                    <div class="warn_box">
                                                        <span class="baseColor nubFont">{{ alarmMaxMin(item) }}</span>
                                                        <edit-alarm v-model="item.editVisible" :paramInfo='item'></edit-alarm>
                                                    </div>
                                                </div>
                                                <!-- <p>
                                                    <span>告警次数：</span>
                                                    <span class="warinColor nubFont">{{item.alarmCount}}次</span>
                                                    <span class="cp cheakwarn el-icon-view"></span>
                                                </p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="right_history_box">
                                    <!-- <charts  :refName='"chart"+i' :options= 'item' class="chart_box"></charts> -->
                                    <charts  :refName="'chart'+i" :options='item' class="chart_box"></charts>
                                </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <hn-emptyData text= '暂无历史数据' size= '180px' style="height: 100%"></hn-emptyData>
                        </template>
                    </div>
                        
                </div>
           </template>
        </HnContent>

    </div>
</template>

<script>
    import charts from './components/charts.vue'
    import editAlarm from './components/editAlarm.vue'
    import { deepClone,downloadFileByBase } from '@/libs/util'
    import config from '@/config'
    import menuFun from '@/mixins/menuFun'
    export default {
        components:{
            charts,
            editAlarm,
        },
        mixins: [menuFun],
        data() {
            return {
                leftStyle: null,
                changLoading: false,
                timer: (new Date()).getTime(),
                paramColorList:[
                    {
                       iconUrl: require('../../../assets/imgs/monitoring/defalut/circle.png'),
                       iconColr:'#00ffc5'
                    },
                    {
                       iconUrl: require('../../../assets/imgs/monitoring/defalut/circle1.png'),
                       iconColr:'#00ccff'
                    },
                    {
                       iconUrl: require('../../../assets/imgs/monitoring/defalut/circle2.png'),
                       iconColr:'#ffa800'
                    },
                    {
                       iconUrl: require('../../../assets/imgs/monitoring/defalut/circle3.png'),
                       iconColr:'#a54ed7'
                    },
                    {
                       iconUrl: require('../../../assets/imgs/monitoring/defalut/circle4.png'),
                       iconColr:'#435ae6'
                    }
                ],
                loadData:{
                    alarmLoad: false, 
                    updateLoad:false,
                    downLoad: false,
                    submitLoad:false,
                },
                nowDataList:[],
                searchParamDateVal: '',
                visible: false,
                chartList: {
                    name: '测试',
                    unit: '%',
                    xAxis: [1,2,3,4,5],
                    yAxis: [42,51,32,78,64] 
                },
                devFromInfo: null,
                devHositoryFromInfo:{
                    timeType: 2, 
                    sTime:'',
                    eTime: ''
                },
                menus:[],
                paramTimeList:[
                   {
                       name: '今天',
                       code: 1
                   },
                    {
                       name: '三天',
                       code: 2
                   },
                   {
                       name: '本周',
                       code: 3
                   }
                   ,
                    {
                       name: '当月',
                       code: 4
                   },
                   {
                       name: '三月',
                       code: 5
                   }
                ],
                // defaultOpenMenu: [0,0], // 默认设备菜单，[0] 为展开索引 [1] 为激活菜单索引
                historyTotalData: 0,
                devHistoryParamsList: [],
                warnform:{
                    devId: '',
                    isReceive: 1, // 1 是 2否
                    collectorIds:[]

                },
                isIndeterminate: true,
                checkAll: false,
                devAlarmSubDetail: [], // 预警订阅详情
                warnVisbise: false,
                updateTime: '暂无更新时间',
                devInfo: null,
                onlineData: config.devStatus.online,
                menuDevType: ['采集设备','采控设备'],
                
            }
        },
        computed:{
            paramValueHandle(){

                 return item => {
                      if(item.paramName.includes('风向')){
                     
                        let valArr = [
                            {
                                max:112.5,
                                min:67.5,
                                name: '北风'
                            },
                            {
                                max:157.5,
                                min:112.5,
                                name: '西北风'
                            },
                            {
                                max:202.5,
                                min:157.5,
                                name: '西风'
                            },
                            {
                                max:247.5,
                                min:202.5,
                                name: '西南风'
                            },
                            {
                                max:292.5,
                                min:247.5,
                                name: '南风'
                            },
                            {
                                max:337.5,
                                min:292.5,
                                name: '东南风'
                            },
                            {
                                max:22.5,
                                min:337.5,
                                name: '东风'
                            },
                            {
                                max:67.5,
                                min:22.5,
                                name: '东北风'
                            }
                        ]
                        let val = parseInt(item.paramVal)
                        let resultName = '无风'
                   
                        valArr.some(item => {
                            
                            if(val > 337.5 || val <= 22.5){
                                resultName= '东风'
                                return true
                            } 
                            if(val> item.min && val<= item.max){
                                resultName= item.name
                                return true
                            }
                        })
                        return resultName
                   
                      }else {
                          return item.paramVal
                      }
                 }
            },
            paramOutIconHanlde(){
                 return index => {
                    let i = this.paramColorList.length > index ? index : index%4
                    let data = this.paramColorList[i]
                    return {
                        backgroundImage: `url(${data.iconUrl})`,
                        backgroundRepeat: 'no-repeat',
                    }
                 }
            },
            alarmMaxMin(){
                return item => {
                    if(item.alarmMax !== null && item.alarmMin !== null){
                       return `${item.alarmMin}${item.paramUnit}~${item.alarmMax}${item.paramUnit}`
                    }else{
                        return '请设置阀值'
                    }
                }  
            },
            paramInIconHanlde(){
                return index => {
                    let i = this.paramColorList.length > index ? index : index%4
                    let data = this.paramColorList[i]
                     return {
                         color: data.iconColr,
                         fontSize: '30px'
                     }
                }
            },
            allCollectorId(){
                let arrList = []
                if(this.devHistoryParamsList.length){
                    
                    this.devHistoryParamsList.forEach(item => {
                         arrList.push(item.collectorSensorId)
                    })
                }
                return arrList
            },
            paramCount() {
               return this.devHistoryParamsList.length
            },
            isOnlie() {
               return this.devInfo && this.devInfo.onlineStatus == this.onlineData ? true : false
            }
        },
        watch:{
           devHositoryFromInfo:{
               handler(){
                 this.reqIotDeviceHistoryDataList()
               },
               deep: true
           }
        },
        methods:{
           
            // 实时设备数据查询
            async reqIotDeviceRealTimeDataList(){
              try {
                const res = await this.$api.IotDeviceRealTimeDataList(this.devFromInfo)
                if(res && res.devRealTimeDatas){
                    this.updateTime = res.latestTime || '暂无更新时间'
                    let realTimeDatas = res.devRealTimeDatas
                    let oldDataId = []
                    this.nowDataList.map(item => oldDataId.push(item.collectorSensorId))
                    if(realTimeDatas && realTimeDatas.length){
                         realTimeDatas.forEach(item => {
                            let i = oldDataId.indexOf(item.collectorSensorId)
                            if(i > -1) this.$set(this.nowDataList[i],'paramVal',item.paramVal)
                         })
                    }
                    this.$nextTick(()=> {
                         
                        this.clearAnime()
                        if(res.devRealTimeDatas.length > 6 ) 
                          this.animeMoreNowWeather(res.devRealTimeDatas.length)
                    })
        
                   
                }
                return res
             } catch {
                return false       
             }
            },
            // 实时数据页面加载
            async reqIotDeviceRealTimeDataByCacheList(){
                try {
                const res = await this.$api.IotDeviceRealTimeDataByCacheList(this.devFromInfo)
                if(res && res.devRealTimeDatas){
                    this.updateTime = res.latestTime
                    this.nowDataList = res.devRealTimeDatas
                    
                    this.$nextTick(()=> {
                         
                        this.clearAnime()
                        if(res.devRealTimeDatas.length > 6 ) 
                          this.animeMoreNowWeather(res.devRealTimeDatas.length)
                        // if(res.devRealTimeDatas.length < 7 ){
                        //    this.animeNowWeather()
                        // } 
                        // else this.animeMoreNowWeather(res.devRealTimeDatas.length)
                    })
                   
                }else{
                    this.nowDataList = []
                }
                return res
             } catch {
                return false       
             }
            },
            //  历史设备数据查询
            async reqIotDeviceHistoryDataList(){
                this.changLoading = true
                const res = await this.$api.IotDeviceHistoryDataList(this.devHositoryFromInfo)
                setTimeout(() => {
                    this.changLoading = false
                }, 500);
                if(res){
                    this.historyTotalData = res.total || 0
                    if(res.devHistoryParams && res.devHistoryParams.length){
                        res.devHistoryParams.forEach(item => {
                            item.editVisible = false
                            item.seeVisible = false
                        })
                        this.devHistoryParamsList = res.devHistoryParams
                    }else{
                        this.devHistoryParamsList = []
                    }
                   
                }

            },
            //  采集历史数据导出
            async reqIotDeviceHistoryDataExport(){
                this.$set(this.loadData,'downLoad',true) 
                const res = await this.$api.IotDeviceHistoryDataExport(this.devHositoryFromInfo)
                
                if(res){
                    downloadFileByBase(res.fileBuffer, res.fileName)
                }
                setTimeout(() => {
                   this.$set(this.loadData,'downLoad',false)   
                }, 300);
                
            },
            // 设备预警订阅明细
            async reqIotDevAlarmSubSingleDetail(){
               const res = await this.$api.IotDevAlarmSubSingleDetail({
                   id: this.devFromInfo.devId
               })
               // 通过errcode判断是否是数组    
               if( res && !res.hasOwnProperty('errcode')){
                  let idsData = []
                  if(res.collectors && res.collectors.length){
                        res.collectors.forEach(item => {
                             if(item.sensorId && item.status == 1){
                                 idsData.push(item.sensorId)
                             }
                        })
                  }
                  this.$set(this.warnform,'isReceive',res.isReceive)
                  this.$set(this.warnform,'collectorIds',idsData)
               }

            },
            // 设备切换
            devChange(infos) {
               let data = infos[0]
               this.devInfo = this.getDevInfo(infos) 
               this.devFromInfo = data  
               Object.assign(this.devHositoryFromInfo,data)
               this.reqIotDeviceRealTimeDataByCacheList()   
               this.reqIotDeviceHistoryDataList()   
            //    this.reqIotDevAlarmSubSingleDetail()       
            },
            // 参数历史数据 固定日期选择
            paramTimeChange(code) {
                
                this.$set(this.devHositoryFromInfo,'timeType',code)
                this.searchParamDateVal= null
                this.$set(this.devHositoryFromInfo,'sTime','')
                this.$set(this.devHositoryFromInfo,'eTime','')

            },
            // 参数历史数据 自定义日期选择
            searchParamDateChange(data){
    
              if(data && data.length == 2){
                   this.$set(this.devHositoryFromInfo,'sTime',data[0])
                   this.$set(this.devHositoryFromInfo,'eTime',data[1])
                   this.$set(this.devHositoryFromInfo,'timeType', 0)

              }
               
            },
            clearAnime(){
            
               if(this.$anime.running.length){
                   this.$anime.remove(['.nowDataList'])
                   this.leftStyle= { left: 0}
                   this.timer= (new Date()).getTime()
               
               } 
            },
            // 页面滚动动画
            animeMoreNowWeather(l){
                
                //    this.$nextTick(()=> {})
                setTimeout(() => {
                    
                        const cWidth = this.$refs.warp.clientWidth
                        this.$anime({
                            targets: ['.nowDataList'],
                            left: -cWidth/2,
                            duration: (Math.round((25*l)/16) * 1200 ),
                            loop: true,
                            easing: 'linear'
                        })
                })
                       
                   
            },
            // 数字滚动动画
            animeNowWeather(){
                setTimeout(() => {
                    let domeList = document.querySelectorAll('.paramNub')
                    domeList.forEach(dome => { 
                            this.$anime({
                                targets: dome,
                                innerHTML: [0,dome.innerHTML],
                                duration: 1000,
                                easing: 'linear',
                                round: 10 
                            });
                    })
                            
                 })
            },
            // 更新实时查询
            updataNowData(){
                 this.$set(this.loadData,'updateLoad',true) 
                 this.reqIotDeviceRealTimeDataList().then(res => {
                   
                     setTimeout(()=> {
                          this.$set(this.loadData,'updateLoad',false)   
                          this.$msg.success('数据更新成功')
                        
                     },500)
                     
                     
                 })
            },
            handleCheckAllChange(val) {
                const allIds = this.allCollectorId
              
                let ids = val ? allIds : [];
               
                this.$set(this.warnform,'collectorIds',ids)
               
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                let infoCount = this.devHistoryParamsList.length
                this.checkAll = checkedCount === infoCount;
                this.isIndeterminate = checkedCount > 0 && checkedCount < infoCount;
            },
            async onSubmit(){
                
                let ids = this.warnform.collectorIds,hanldeIds= []
                if(ids.length){
                   ids.forEach(item => {
                       hanldeIds.push({
                           sensorId: item,
                           status: 1
                       })
                   })
                }
                // 深拷贝表单数据
               let hanldeData = deepClone(this.warnform)
               hanldeData.collectorIds = hanldeIds
               hanldeData.devId = this.devFromInfo.devId
               this.$set(this.loadData,'submitLoad',true) 
               const res = await this.$api.SetSingleIotDevAlarmSub(hanldeData)
               if(res){
                  setTimeout(()=> {
                    this.$set(this.loadData,'submitLoad',false)   
                    this.$msg.success('预警订阅设置成功')
                    this.reqIotDevAlarmSubSingleDetail() 
                 },500)
               }else{
                   this.$set(this.loadData,'submitLoad',false) 
               }
               
                
            },
            onCalce(){
                this.warnVisbise= false
            },
           
        }
    }
</script>

<style lang="scss" scoped>
.monitoring_main_box{
    .content_box{
       border-radius: 8px;
       box-sizing: border-box;
       padding:0 20px;
       .nowData_title_box{
           color: #fff;
           display: flex;
           justify-content: space-between;
           align-items: center;
           padding: 16px 0 14px 0;
           & > div:first-child{
              & > span:first-child{
               font-size: 18px;
              }
              & > span:last-child{
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 12px;
              }
           }
           & > div:last-child{
               & > div{
                  width: 100px;
                  height: 32px;
                  line-height: 32px;
                  text-align: center;
                  border: 1px solid #6b7277;
                  border-radius: 8px;
                  font-size: 15px;
               }
           }
           
       }
       .nowData_content_box{
           width: 100%;
           height: 120px;
           border-radius: 8px;
           overflow: hidden;
           position: relative;
           .nowDataList{
               height: 100%;
               position: absolute;
               left: 0;
               top: 0;     
               display: flex;
               & > li {
                   min-width: 261px;
                   height: 100%;
                   position: relative;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   & > div:first-child{
                       width: 60px;
                       height: 60px;
                       position: relative;
                       & > div{
                          width: 100%;
                          height: 100%;
                          animation:turn 4s linear infinite;
                       }
                       & > span{
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          transform:translate(-50%,-50%);
                          
                       }                     
                   }
                   & > div:last-child{
                       margin-left: 10px;
                       & > p:first-child {
                           color: #fff;
                           font-size: 14px;
                           .paramNub{
                                font-size: 30px;
                                font-family: 'agencyr';
                            }
                            .paramWind{
                                font-size: 22px;
                            }
                       }
                       & > p:last-child {
                           font-size: 14px;
                           color: rgba(255,255,255, 0.4);    
                       }
                   }
               }
               & > li:not(:last-child)::before{
                   width: 1px;
                   height: 80px;
                   display: block;
                   background: linear-gradient(
                    to top, 
                    rgba(0,0,0,0) 0%,  
                    #fff 50%, 
                    rgba(0,0,0,0) 100%);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -40px;
                   content: '';
               }
           }
       }
       .history_title_box{
           color: #fff;
           display: flex;
        //    justify-content: space-between;
           padding: 16px 0;
           .title_box{
               font-size: 18px;
               display: flex;
               align-items: center;
               &> span:last-child{
                   font-size: 14px;
                   display: inline-block;
                   margin-left: 10px;
               }
               .fontfanmily{
                   font-family: 'agencyr';
                   font-size: 18px;
               }
           }
           .check_box{
               flex: 1;
               display: flex;
               justify-content: center;
               align-items: center;
               ul.dateType_box{
                       display: flex;
                    //    width: 214px;
                       height: 30px;
                       line-height: 30px;
                       font-size: 12px;
                       color: #fff;
                       border-radius: 15px;
                       margin-left: 15px;
                       & > li {
                           padding: 0 18px;
                           border-radius: 15px;
                           
                       }
                       & > li:not(:last-child){
                          margin-right: 8px;
                       }
        
                }
           }
           .tools_box{
               display: flex;
               font-size: 15px;
               & > div{
                 width: 100px;
                  height: 32px;
                  line-height: 32px;
                  text-align: center;
                  border-radius: 8px;
               }
               & >div:first-child{
                    border: 1px solid #6b7277;   
               }
               & > .downBut{
                //    margin-left: 12px;
                   min-width: 90px;
                   color: #fff;
                   border: 1px solid #ffa800;
                   background: rgba(#ffa800,0.2);
                   margin-left: 15px;
               }
                  
           }
       }
       .history_content_box{
           width: 100%;
           height: 520px;
           & > div{
              width: 100%;
              height: 180px;
              margin-bottom: 10px;
              border-radius: 8px;
              display: flex;
              .left_history_box{
                 width: 490px;
                 height: 100%;
                 flex-shrink: 0;
                 & > p {
                     margin-top: 20px;
                     padding-left: 20px;
                 }
                 & > div{
                     width: 100%;
                     height: calc(100% - 41px);
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     & > div{
                         width: 220px;
                         height: 90px;
                         background: url('../../../assets/imgs/monitoring/defalut/label.png');
                     }
                     & > div:first-child{
                         display: flex;
                         align-items: center;
                         & > div:last-child{
                             flex: 1;
                             font-size: 14px;
                             & > p{
                                 padding: 2px 0;
                             }
                             & > p:nth-child(2) >span{
                                 color: #ffa800;
                             }
                         }
                        .paramIcon{
                            width: 100px;
                            & > div{
                                width: 60px;
                                height: 60px;
                                position: relative;
                                margin: 0 auto;
                                & > div{
                                    width: 100%;
                                    height: 100%;
                                    animation:turn 4s linear infinite;
                                }
                                & > span{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform:translate(-50%,-50%);
                                    
                                }  
                            }
                                  
                         }
                         
               
                     }
                     & > div:last-child{
                         margin-left: 10px;
                         display: flex;
                         justify-content: center;
                         align-items: center;
                         .flex_content{
                              font-size: 14px;
                              & > div{
                                  padding: 2px 0;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  flex-direction: column;
                                  .warn_box {
                                      display: flex;
                                  }
                                //   .edit_box{
                                //       display: inline-block;
                                //       margin-left: 5px;
                                //   }
                                //   .cheakwarn{
                                //       display: inline-block;
                                //       margin-left: 5px;
                                     
                                //   }
                              }
                              .warinColor{
                                  color: #ffa800;
                              }

                         }
                     }

                 }
              }
              .right_history_box{
                  flex: 1;
                  height: 100%;
              }
           }
       }
    }
    .el-button{
        border-radius: 6px;
    }
}

</style>