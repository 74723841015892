<template>
    <el-popover
    placement="top"
    width="230"
    v-model="Visible"
    trigger="click">
        <div class="editAlarm_box">
            <el-form :model="fromInfo" ref="ruleForm" :rules="rules" class="demo-form-inline" size='small' label-width="96px" label-position="left">
                <el-form-item label="告警阈值 <" prop= 'alarmMin'>
                     <!-- <el-input-number v-model="fromInfo.alarmMin" controls-position="right" style="width:100%"></el-input-number> -->
                     <el-input v-model="fromInfo.alarmMin" type="number" placeholder="请输入最小值">
                         <span slot="suffix">{{ unit }}</span>
                     </el-input>
                </el-form-item>
                <el-form-item label="告警阈值 >" prop= 'alarmMax'>
                    <el-input v-model="fromInfo.alarmMax" type="number" placeholder="请输入最大值">
                         <span slot="suffix">{{ unit }}</span>
                     </el-input>
                   <!-- <el-input-number  controls-position="right" style="width:100%"></el-input-number> -->
                </el-form-item>
                <!-- <el-form-item>  </el-form-item> -->
                <div class="butInfo_box">
                   <el-button @click="onCalce" size='small'>取消</el-button>
                   <el-button type="primary" @click="onSubmit('ruleForm')" class="but_box" size='small' :loading="submitLoad">设置</el-button>
                </div>
                    
              
            </el-form>
           
        </div>
        <span slot="reference" class="edit_box cp el-icon-edit"></span>
    </el-popover>
</template>

<script>
    export default {
        props:{
            value: {
                type: Boolean,
                default: false
            },
            paramInfo: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data(){
            return{
                Visible:false,
                submitLoad: false,
                unit: '',
                fromInfo:{
                    alarmMin: '',
                    alarmMax: '',
                    collectorSensorId:''
                },
                rules:{
                    alarmMin: [
                       { required: true, message: '请输入最小值', trigger: 'blur' },
                    ],
                    alarmMax: [
                       { required: true, message: '请输入最大值', trigger: 'blur' },
                    ],
                }
            }
        },
        created(){
            this.Visible = this.value
            Object.keys(this.fromInfo).forEach(key => {
                 if(this.paramInfo.hasOwnProperty(key))
                    this.$set(this.fromInfo,key,this.paramInfo[key]) 
                 
            }) 
            this.unit = this.paramInfo.paramUnit || ''
            
        },
        watch:{
            value:{
                handler(val){
                    this.Visible = val
                }
            }
        },
        methods:{
            onCalce(){
               this.Visible = false
            },
            onSubmit(formName){
                
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.submitLoad= true
                            this.$api.EditCollectorSensorAlarm(this.fromInfo).then(res => {
                                
                                if(res){
                                  
                                    setTimeout(()=>{
                                        this.submitLoad= false
                                        this.Visible = false
                                        this.$msg.success('告警参数设置成功');
                                        this.$emit('updata')
                                        this.$set(this.paramInfo,'alarmMin',this.fromInfo.alarmMin)
                                        this.$set(this.paramInfo,'alarmMax',this.fromInfo.alarmMax)
                                        
                                    },500)
                                    
                                }else{
                                    this.submitLoad= false
                                }
                            }).catch(err => {
                                this.submitLoad= false
                            })
                        } else {
            
                            return false;
                        }
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
.editAlarm_box{
    width: 100%;
    padding: 6px 0;
}
.edit_box{
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
}
.demo-form-inline{
   width: 90%;
   margin: 0 auto;
}
.demo-form-inline ::v-deep .el-form-item__label{
  color: #fff;
}
.but_box{
    min-width: 60px;
    color: #fff;

}
.butInfo_box{
    text-align: right;
}
.but_box:hover{
    color: #fff;
}
.el-input ::v-deep .el-input__inner{
    text-align: center;
}
</style>